import { memo } from "react";
import type { FunctionComponent } from "react";

import { Portal } from "scmp-app/components/common/portal";
import { useNewsletterWidget } from "scmp-app/components/newsletter/newsletter-widget-container/hooks";
import { FooterElementID } from "scmp-app/pages/_app/consts";

import {
  DynamicFooter,
  DynamicPlusFooter,
  DynamicPostiesFooter,
  DynamicPostMagazineFooter,
  DynamicStyleFooter,
} from "./dynamics";
import { Container } from "./styles";

export enum FooterVariant {
  Dark = "dark",
  MagazinesStyle = "magazines-style",
  PlusWithAppLinkRow = "plus-with-app-link-row",
  PlusWithoutAppLinkRow = "plus-without-app-link-row",
  PlusWithoutAppLinkRowAndFixed = "plus-without-app-link-row-and-fixed",
  Posties = "posties",
  PostMagazine = "post-magazine",
}

export type Props = {
  className?: string;
  variant?: `${FooterVariant}`;
};

const AppFooter_: FunctionComponent<Props> = ({ className, variant }) => {
  useNewsletterWidget({
    contentType: "footer",
    eventCategory: "Newsletter (Footer - Inline)",
    section: "",
  });

  const renderFooter = () => {
    switch (variant) {
      case FooterVariant.Dark:
        return <DynamicFooter variant={FooterVariant.Dark} />;
      case FooterVariant.MagazinesStyle:
        return <DynamicStyleFooter />;
      case FooterVariant.PlusWithAppLinkRow:
        return <DynamicPlusFooter withAppLinkRow />;
      case FooterVariant.PlusWithoutAppLinkRow:
        return <DynamicPlusFooter withAppLinkRow={false} />;
      case FooterVariant.PlusWithoutAppLinkRowAndFixed:
        return <DynamicPlusFooter fixed withAppLinkRow={false} />;
      case FooterVariant.Posties:
        return <DynamicPostiesFooter />;
      case FooterVariant.PostMagazine:
        return <DynamicPostMagazineFooter />;
      default:
        return <DynamicFooter />;
    }
  };

  return (
    <Portal selector={`#${FooterElementID}`}>
      <Container $variant={variant} className={className}>
        {renderFooter()}
      </Container>
    </Portal>
  );
};

export const AppFooter = memo(AppFooter_);

AppFooter.displayName = "AppFooter";
