import type {
  FollowButtonDisplay,
  FollowButtonLoadingRelatedProps,
  FollowButtonOutline,
  FollowButtonSize,
  FollowButtonVariant,
  FollowType,
} from "@product/scmp-sdk";
import {
  FollowButtonAction,
  FollowButton as FollowButtonComponent,
  getLoginDialogTitleAndDescription,
} from "@product/scmp-sdk";
import { useAtomValue } from "jotai";
import qs from "qs";
import type { FunctionComponent } from "react";
import { useEffect, useState } from "react";

import { accountAtom } from "shared/lib/account";

import { useLoginDialogStateHelper } from "scmp-app/components/login-dialog/hooks";
import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import { useCurrentPageType } from "scmp-app/lib/router/hooks";

import { useUserFollow } from "./hooks";
import { ChildrenContainer } from "./styles";
import type { FollowButtonSource, FollowQueryStringPayload } from "./types";
import { computeGa4CustomizedParameters } from "./utils";

export type Props = {
  className?: string;
  disabled?: boolean;
  display?: FollowButtonDisplay;
  entityIds: string[];
  name: string;
  onClick?: (isActionFollow: boolean) => void;
  outline?: FollowButtonOutline;
  size?: FollowButtonSize;
  source: FollowButtonSource;
  type: FollowType;
  variant?: FollowButtonVariant;
} & FollowButtonLoadingRelatedProps;

export const FollowButton: FunctionComponent<Props> = ({
  className,
  disabled = false,
  display,
  entityIds,
  isLoadingEnabled,
  loadingDelay,
  name,
  onClick,
  outline,
  size,
  source,
  type,
  variant,
}) => {
  const { checkIsFollowed, handleFollowAction } = useUserFollow();
  const { isLoggedIn } = useAtomValue(accountAtom);

  const { openLoginDialog } = useLoginDialogStateHelper();
  const currentPageType = useCurrentPageType();

  const handleFollowClick = (type: FollowType, entityIds: string[], campaignName: string) => {
    if (isLoggedIn) {
      handleFollowAction(entityIds, type, FollowButtonAction.Follow, () => {
        sendGA4Tracking({
          action: "sys",
          category: "follow",
          customized_parameters: {
            ...computeGa4CustomizedParameters({
              currentPageType,
              entityIds,
              source,
              type,
            }),
            action_type: FollowButtonAction.Follow,
          },
          subcategory: "result",
        });
      });
    } else {
      const queryStringPayload: FollowQueryStringPayload = {
        action: {
          follow: {
            [type]: entityIds,
          },
        },
        source,
      };
      const accountUrl = new URL(window.location.href);
      accountUrl.search = qs.stringify(queryStringPayload);

      const { description, title } = getLoginDialogTitleAndDescription({
        campaignName,
        type,
      });

      openLoginDialog({
        description,
        destination: accountUrl.toString(),
        ga4CustomParameter: {
          follow_name: campaignName,
          follow_type: type,
          trigger_point: "follow",
        },
        title,
      });
    }
  };

  const handleUnfollowClick = (type: FollowType, entityIds: string[]) => {
    handleFollowAction(entityIds, type, FollowButtonAction.Unfollow, () => {
      sendGA4Tracking({
        action: "sys",
        category: "follow",
        customized_parameters: {
          ...computeGa4CustomizedParameters({
            currentPageType,
            entityIds,
            source,
            type,
          }),
          action_type: FollowButtonAction.Unfollow,
        },
        subcategory: "result",
      });
    });
  };

  const [isFollowingEveryEntity, setIsFollowingEveryEntity] = useState(false);
  useEffect(() => {
    const isEveryEntityFollowed = entityIds.every(entityId => checkIsFollowed(type, entityId));
    setIsFollowingEveryEntity(isEveryEntityFollowed);
  }, [entityIds, checkIsFollowed, type]);

  const handleClick = () => {
    sendGA4Tracking({
      action: "click",
      category: "follow",
      customized_parameters: {
        ...computeGa4CustomizedParameters({
          currentPageType,
          entityIds,
          source,
          type,
        }),
        action_type: isFollowingEveryEntity
          ? FollowButtonAction.Unfollow
          : FollowButtonAction.Follow,
      },
      subcategory: "",
    });
    if (isFollowingEveryEntity) {
      handleUnfollowClick(type, entityIds);
    } else {
      handleFollowClick(type, entityIds, name);
    }
    onClick?.(!isFollowingEveryEntity);
  };

  return (
    <ChildrenContainer className={className}>
      <FollowButtonComponent
        disabled={disabled}
        display={display}
        isFollowing={isFollowingEveryEntity}
        isLoadingEnabled={isLoadingEnabled}
        loadingDelay={loadingDelay}
        onClick={handleClick}
        outline={outline}
        size={size}
        variant={variant}
      />
    </ChildrenContainer>
  );
};

FollowButton.displayName = "FollowButton";
