import dynamic from "next/dynamic";

import type { Props as FooterProps } from "scmp-app/components/footer";
import type { PlusFooterProps } from "scmp-app/components/plus/footer";

export const DynamicPostiesFooter = dynamic<Record<string, never>>(() =>
  import("scmp-app/components/posties/footer").then(module => module.PostiesFooter),
);

export const DynamicStyleFooter = dynamic<Record<string, never>>(() =>
  import("scmp-app/components/section/section-style/footer").then(module => module.StyleFooter),
);

export const DynamicFooter = dynamic<FooterProps>(() =>
  import("scmp-app/components/footer").then(module => module.Footer),
);

export const DynamicPlusFooter = dynamic<PlusFooterProps>(() =>
  import("scmp-app/components/plus/footer").then(module => module.PlusFooter),
);

export const DynamicPostMagazineFooter = dynamic<Record<string, never>>(() =>
  import("scmp-app/components/post-magazine/footer").then(module => module.PostMagazineFooter),
);
