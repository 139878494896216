import { notEmpty, useTrackImpressionEventByIntersection } from "@product/scmp-sdk";
import { type FunctionComponent, useCallback } from "react";
import { graphql, useFragment } from "react-relay";

import { getContentSponsor } from "scmp-app/components/content/content-sponsor-tag/helpers";
import type { Variant as TopicLinkVariant } from "scmp-app/components/content/content-topic-link";
import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import type { RecirculationWidgetImpressionEvent } from "scmp-app/components/tracking/google-analytics-4/types";
import type { topStoriesItemContent$key } from "scmp-app/queries/__generated__/topStoriesItemContent.graphql";

import { Container, StyledContentItemHomePrimary, StyledContentItemHomeSecondary } from "./styles";

export type Props = {
  className?: string;
  currentTopicReference?: string;
  isShowImage?: boolean;
  onClick?: (entityId?: string, urlAlias?: string, isSponsorArticle?: boolean) => void;
  reference: topStoriesItemContent$key;
  topicLinkVariant?: TopicLinkVariant;
  variant: "primary" | "secondary";
  withCaption?: boolean;
  withSection?: boolean;
  withTopic?: boolean;
};

export const TopStoriesItem: FunctionComponent<Props> = ({
  className,
  currentTopicReference,
  isShowImage = false,
  onClick,
  reference,
  topicLinkVariant,
  variant,
  withCaption = true,
  withSection = true,
  withTopic,
}) => {
  const content = useFragment(
    graphql`
      fragment topStoriesItemContent on Content {
        ...homePrimaryContentItemContent
        ...homeSecondaryContentItemContent
        ...helpersUseContentSponsorContent
      }
    `,
    reference,
  );

  const contentSponsor = getContentSponsor(content);
  const isSponsorArticle = notEmpty(contentSponsor?.name);

  const sponsorArticleImpressionGA4Event = useCallback<() => RecirculationWidgetImpressionEvent>(
    () => ({
      action: "imp",
      category: "recirculation",
      customized_parameters: {
        page_type: "homepage",
        widget_name: "sponsor_article",
      },
      subcategory: "widget",
    }),
    [],
  );

  const { captureTrackImpressionEventTargetElement: captureSponsorArticleImpressionEvent } =
    useTrackImpressionEventByIntersection({
      ga4TrackingHandler: sendGA4Tracking,
      getGa4Event: sponsorArticleImpressionGA4Event,
      options: { isSendGA4Tracking: true, shouldSendOnce: true },
    });

  const renderItem = () => {
    switch (variant) {
      case "primary":
        return (
          <StyledContentItemHomePrimary
            currentTopicReference={currentTopicReference}
            onClick={(entityId, urlAlias) => {
              onClick?.(entityId, urlAlias);
            }}
            reference={content}
            topicLinkVariant={topicLinkVariant}
            withCaption={withCaption}
            withComment={true}
            withLiveTag={true}
            withSection={withSection}
            withTopic={withTopic}
          />
        );
      case "secondary":
        return (
          <StyledContentItemHomeSecondary
            currentTopicReference={currentTopicReference}
            onClick={(entityId, urlAlias) => {
              onClick?.(entityId, urlAlias, isSponsorArticle);
            }}
            ref={isSponsorArticle ? captureSponsorArticleImpressionEvent : null}
            reference={content}
            topicLinkVariant={topicLinkVariant}
            withComment={true}
            withImage={isShowImage}
            withLiveTag={true}
            withSection={withSection}
            withSummary={true}
            withTopic={withTopic}
          />
        );
    }
  };

  return <Container className={className}>{renderItem()}</Container>;
};

TopStoriesItem.displayName = "TopStoriesItem";
