import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { transientOptions } from "@product/scmp-sdk";

import type { FooterVariant } from ".";

export type ContainerProps = {
  $variant?: `${FooterVariant}`;
};
export const Container = styled("div", {
  ...transientOptions,
})<ContainerProps>`
  ${props => {
    switch (props.$variant) {
      case "plus-with-app-link-row":
      case "plus-without-app-link-row":
        return css`
          background-color: transparent;
        `;
      default:
        return css`
          background-color: #ffffff;
        `;
    }
  }}
`;
